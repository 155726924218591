var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "SapFilesListView" },
    [
      _c(
        "ui-card",
        { attrs: { header: "SAP monthly exports" } },
        [
          _c(
            "div",
            {
              staticClass:
                "SapListViewActions w-100 mb-3 d-flex justify-content-end",
            },
            [
              _c(
                "ui-button",
                {
                  attrs: {
                    size: _vm.SIZES.small,
                    square: "",
                    loading: _vm.isSapLoading,
                  },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.getSapFilesList(_vm.activeOperatorUuid)
                    },
                  },
                },
                [_c("ui-icon", { attrs: { icon: _vm.ICONS.refresh } })],
                1
              ),
              _c(
                "ui-dropdown",
                { attrs: { placement: _vm.PLACEMENTS.left } },
                [
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: _vm.SIZES.small,
                        face: _vm.FACES.transparent,
                        color: _vm.GRAYSCALE.inkLight,
                        square: "",
                      },
                      slot: "trigger",
                    },
                    [
                      _c("ui-icon", {
                        attrs: {
                          icon: _vm.ICONS.optionsHorizontalFull,
                          color: _vm.GRAYSCALE.inkLight,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("ui-dropdown-actions", {
                    attrs: { slot: "content" },
                    domProps: { actions: _vm.sapActions },
                    slot: "content",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("MuiTable", {
            attrs: {
              "content-cells": _vm.sapSchema,
              "data-set": _vm.sapFilesData,
              "is-loading": _vm.isSapLoading,
            },
          }),
        ],
        1
      ),
      _vm.isSapExportModalOpen
        ? _c("SapExportFormComponent", {
            attrs: { title: "SAP Export" },
            on: {
              closeModal: function ($event) {
                _vm.isSapExportModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }