<template>
  <PageView class="SapFilesListView">
    <ui-card header="SAP monthly exports">
      <div class="SapListViewActions w-100 mb-3 d-flex justify-content-end">
        <ui-button
          :size="SIZES.small"
          square
          :loading="isSapLoading"
          @clickbutton="getSapFilesList(activeOperatorUuid)"
        >
          <ui-icon :icon="ICONS.refresh" />
        </ui-button>
        <ui-dropdown :placement="PLACEMENTS.left">
          <ui-button
            slot="trigger"
            :size="SIZES.small"
            :face="FACES.transparent"
            :color="GRAYSCALE.inkLight"
            square
          >
            <ui-icon
              :icon="ICONS.optionsHorizontalFull"
              :color="GRAYSCALE.inkLight"
            />
          </ui-button>
          <ui-dropdown-actions
            slot="content"
            :actions.prop="sapActions"
          />
        </ui-dropdown>
      </div>
      <MuiTable
        :content-cells="sapSchema"
        :data-set="sapFilesData"
        :is-loading="isSapLoading"
      />
    </ui-card>

    <SapExportFormComponent
      v-if="isSapExportModalOpen"
      title="SAP Export"
      @closeModal="isSapExportModalOpen = false"
    />
  </PageView>
</template>
<script>
import get from 'lodash/get';
import { MuiTable } from '@emobg/vue-base';
import { SAP_SCHEMA } from '@domains/Invoicing/Invoices/Invoice/Sap/const/sap.const';
import { PageView } from '@/components';
import SapExportFormComponent from '@domains/Invoicing/Invoices/partials/SapExportFormComponent';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  components: {
    MuiTable,
    PageView,
    SapExportFormComponent,
  },
  data() {
    return {
      isSapExportModalOpen: false,
      isActionHidden: true,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      sapFilesData: state => get(state, 'sapList.data') || [],
      isSapLoading: state => get(state, 'sapList.isLoading'),
    }),
    sapSchema() {
      return SAP_SCHEMA(this.openSapModal);
    },
    sapActions() {
      return [{
        label: 'Generate export',
        action: () => this.openSapModal(),
      }];
    },
  },
  watch: {
    activeOperatorUuid(newUuid) {
      this.getSapFilesList(newUuid);
    },
  },
  async created() {
    await this.getSapFilesList(this.activeOperatorUuid);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'getSapFilesList',
    ]),
    openSapModal() {
      this.isSapExportModalOpen = true;
    },
  },
};
</script>
